import * as types from "./CartTypes";

//Replace action name and update action types
export const updateCart = (item) => ({
  type: types.UPDATE_CART,
  payload: item,
});
export const processCart = (data) => ({
  type: types.PROCESS_CART,
  payload: data,
});
export const deleteFromCart = (item) => ({
  type: types.DELETE_FROM_CART,
  payload: item,
});
export const saveCheckoutForm = (formValues) => ({
  type: types.SAVE_CHECKOUT_DETAILS,
  payload: formValues,
});
export const setDelCharge = (payload) => ({
  type: types.SET_DEL_CHARGE,
  payload: payload,
});
export const clearCart = () => ({
  type: types.CLEAR_CART,
});

export const setDiscountType = (payload) => ({
  type: types.SET_DISCOUNT_TYPE,
  payload: payload,
});

export const clearDiscountType = () => ({
  type: types.CLEAR_DISCOUNT_TYPE,
});

export const walletModal = (payload) => ({
  type: types.WALLET_MODAL,
  payload: payload,
});

export const setOrderTypeModal = (payload) => ({
  type: types.SET_ORDER_TYPE_MODAL,
  payload,
});

export const setDiscountRemoveModal = (payload) => ({
  type: types.DISCOUNT_REMOVE_MODAL,
  payload: payload,
});

export const savePriceSummary = (priceSummary) => ({
  type: types.PRICE_SUMMARY,
  payload: priceSummary,
});
export const getCoupon = (payload) => ({
  type: types.GET_COUPON_REQUEST,
  payload,
});

export const getCouponReceive = (payload) => ({
  type: types.GET_COUPON_RECEIVE,
  payload,
});

export const setUserConsent = (payload) => ({
  type: types.SET_CONSENT,
  payload,
});

export const selectCoupon = (payload) => ({
  type: types.SELECT_COUPON,
  payload,
});

export const clearCoupon = () => ({
  type: types.CLEAR_COUPON,
});

export const setAvailableCoupons = (payload) => ({
  type: types.SET_AVAILABLE_COUPONS,
  payload,
});

export const clearAvailableCoupons = () => ({
  type: types.CLEAR_AVAILABLE_COUPONS,
});

export const validateCoupon = (payload) => ({
  type: types.VALIDATE_COUPON,
  payload,
});

export const setCouponValidationResult = (payload) => ({
  type: types.SET_COUPON_VALIDATION_RESULT,
  payload,
});

export const IsValidating = (payload) => ({
  type: types.IS_COUPON_VALIDATING,
  payload,
});

export const setIsFreeDeliveryCouponApplied = (payload) => ({
  type: types.IS_FREE_DELIVERY_COUPON_APPLIED,
  payload,
});

export const setFreeDeliveryAmountApplied = (payload) => ({
  type: types.FREE_DELIVERY_AMOUNT_APPLIED,
  payload,
});

export const selectTable = (payload) => ({
  type: types.SELECT_TABLE,
  payload,
});

export const setValidatedCoupon = (payload) => ({
  type: types.SET_VALIDATED_COUPON,
  payload,
});

export const removeValidatedCoupon = () => ({
  type: types.REMOVE_VALIDATED_COUPON,
});

export const clearTableSelection = () => ({
  type: types.CLEAR_TABLE_SELECTION,
});

export const setGuestCount = (payload) => ({
  type: types.SET_GUESTS_COUNT,
  payload,
});

export const setWalletRedeemed = (payload) => ({
  type: types.SET_WALLET_REDEEMED,
  payload,
});

export const setTipAmount = (payload) => ({
  type: types.SET_TIP_AMOUNT,
  payload,
});

export const clearTipAmount = () => ({
  type: types.CLEAR_TIP_AMOUNT,
});
