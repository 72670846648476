import * as types from "./RestaurantTypes";

//Replace action name and update action types
// export const setRestaurantKey = (payload) => ({
//     type: types.SET_RESTAURANT_KEY,
//     payload
// });

export const getRestaurant = (payload, tableId) => ({
  type: types.GET_RESTAURANT_REQUEST,
  payload,
  tableId,
});

export const isGetRestaurantReceived = (payload) => ({
  type: types.IS_RESTAURANT_RECEIVED,
  payload,
});

export const getRestaurantReceive = (payload) => ({
  type: types.GET_RESTAURANT_RECEIVE,
  payload,
});

export const setIsMobileApp = (payload) => ({
  type: types.SET_IS_MOBILE_APP,
  payload,
});

export const clearResturant = () => ({
  type: types.CLEAR_RESTURANT,
});
export const setTableId = (payload) => ({
  type: types.SET_TABLE_ID,
  payload,
});
export const getTableDetails = (payload) => ({
  type: types.GET_TABLE_DETAILS,
  payload,
});
export const setTableDetails = (payload) => ({
  type: types.SET_TABLE_DETAILS,
  payload,
});
export const setTablePeopleCount = (payload) => ({
  type: types.SET_TABLE_PEOPLE_COUNT,
  payload,
});
export const tableOptionRequest = (payload) => ({
  type: types.TABLE_OPTION_REQUEST,
  payload,
});
export const clearTable = () => ({
  type: types.CLEAR_TABLE,
});

export const setLatestAppVersion = (payload) => ({
  type: types.SET_APP_VERSION,
  payload,
});

export const getRestaurantTables = (payload) => ({
  type: types.GET_RESTAURANT_TABLES,
  payload,
});

export const getRestaurantTablesReceive = (payload) => ({
  type: types.GET_RESTAURANT_TABLES_RECEIVE,
  payload,
});

export const setUserUniqueId = (payload) => ({
  type: types.SET_USER_ID,
  payload,
});

export const setIsTodayHoliday = (isTodayHoliday, todayHolidayLabel) => ({
  type: types.IS_TODAY_HOLIDAY,
  payload: {
    isTodayHoliday,
    todayHolidayLabel,
  },
});
export const setIsProfileSecondPage = (payload) => ({
  type: types.IS_PROFILE_SECOND_PAGE,
  payload,
});

export const getUserWalletPointsRequest = (payload) => ({
  type: types.GET_WALLET_POINTS_REQUEST,
  payload,
});

export const getUserWalletPointsReceive = (payload) => ({
  type: types.GET_WALLET_POINTS_RECEIVE,
  payload,
});

export const resetUserWalletDetails = () => ({
  type: types.RESET_USER_WALLET_DETAILS,
});

export const setUserWalletError = (payload) => ({
  type: types.SET_WALLET_ERROR,
  payload,
});

export const setIsAppFlavour = (payload) => ({
  type: types.SET_IS_APP_FLAVOUR,
  payload,
});

export const setLinkedRest = (payload) => ({
  type: types.SET_LINKED_REST,
  payload,
});

export const setIsBirthday = (payload) => ({
  type: types.SET_IS_BIRTHDAY,
  payload,
});

export const setNoOrderType = (data) => ({
  type: types.IS_NO_ORDER_TYPE,
  payload: data,
});
